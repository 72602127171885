<template>
  <div>
    <header class="header" :class="{'mobile-bg':!isShow}">
      <div v-if="!$store.state.app.isMobile"
           class="web-header">
        <div class="header-left">
          <div class="nav-logo">
            <router-link
                to="/"
                tag="img"
                :src="require(`@/assets/images/logo/logo-black.png`)"/>
          </div>
          <div class="menu">
            <component
                :is="resolveNavComponent(item)"
                v-for="(item, itemKey) in navMenuItems"
                :key="'headerMenu' + itemKey"
                :item="item"
            />
          </div>
        </div>
        <div v-if="$store.state.app.user" class="header-right">
          <v-hover v-slot="{hover}">
            <div>
              <v-icon>mdi-wallet-outline</v-icon>
              <v-fade-transition>
                <v-card
                    v-if="hover"
                    class="wallet"
                    outlined
                    max-width="300px"
                >
                  <p class="wallet-title">
                    YOUR WALLET
                  </p>
                  <div class="wallet-box">
                    <p>Your current MetaMask wallet address does not match any of your wallets connected to royal.</p>
                    <a href="#">Go To Wallet Settings </a>
                  </div>
                  <v-list flat>
                    <v-list-item-group
                        v-model="selectedItem"
                        color="primary"
                    >
                      <v-list-item
                          class="wallet-list"
                          v-for="(item, i) in items"
                          :key="i"
                          @click="goPage('wallets')"
                      >
                        <v-list-item-icon>
                          <img :src="require(`@/assets/images/logo/${item.icon}-logo.svg`)" alt="crypto-logo"
                               width="16" height="16"/>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.title"></v-list-item-title>
                          <p>{{ item.address }}</p>
                        </v-list-item-content>
                        <v-badge color="#DCDCDC" dot
                        ></v-badge>
                      </v-list-item>
                    </v-list-item-group>
                    <v-divider></v-divider>
                  </v-list>
                  <v-btn class="wallet-button" text>
                    + ADD A WALLET
                  </v-btn>
                </v-card>
              </v-fade-transition>
            </div>
          </v-hover>
          <v-hover v-slot="{hover}">
            <div>
              <v-avatar
                  size="30">
                <img
                    :src=profileInfo.avatarAsset.filename
                    alt="profile-avatar"
                >
              </v-avatar>
              <v-fade-transition>
                <v-card
                    v-if="hover"
                    class="avatar"
                    outlined
                    width="170px"
                >
                  <p @click="goPage('my-profile')">MY PROFILE</p>
                  <p @click="goPage('profile')">SETTINGS</p>
                  <p>CONTACT SUPPORT</p>
                  <p @click="logout">LOGOUT</p>
                </v-card>
              </v-fade-transition>
            </div>
          </v-hover>
        </div>
        <div v-else class="header-right">
          <div class="before-login">
            <a @click="goPage('auth')">LOGIN</a>
            <a @click="goPage(`register`)">SIGN UP</a>
          </div>
        </div>
      </div>

      <div class="mobile-header" v-else>
        <div class="header-left">
          <v-icon @click.stop="drawer = !drawer">mdi-menu</v-icon>
          <div class="mobile-logo">
            <router-link
                to="/"
                tag="img"
                :src="require(`@/assets/images/logo/logo-black.png`)"
            />
          </div>
        </div>
        <v-navigation-drawer
            class="navigation-drawer"
            v-model="drawer"
            absolute
            temporary
            style="width: 100%; max-width:300px;height: 100vh; padding: 24px;"
        >
          <div class="drawer-top">
            <div class="drawer-topmost">
              <div class="mobile-logo">
                <router-link
                    to="/"
                    tag="img"
                    :src="require(`@/assets/images/logo/logo-black.png`)"
                />
              </div>
              <v-icon @click.stop="drawer = !drawer">mdi-close-thick</v-icon>
            </div>
            <v-list dense>
              <v-list-item
                  v-for="item in drawerItems"
                  :key="item.title"
                  link
                  @click="goPage(item.name)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="$store.state.app.user" @click="goPage('my-profile')">
                <v-list-item-content>
                  <v-list-item-title>PROFILE</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div class="drawer-bottom">
            <div>
              <router-link
                  to="/auth"
              >LOGIN
              </router-link>
            </div>
            <div>Privacy</div>
            <div>@2021 Royal Markets Inc.</div>
          </div>
        </v-navigation-drawer>
        <div v-if="$store.state.app.user" class="header-right">
          <v-hover v-slot="{hover}">
            <div>
              <v-icon>mdi-wallet-outline</v-icon>
              <v-fade-transition>
                <v-card
                    v-if="hover"
                    class="wallet"
                    outlined
                    max-width="300px"
                >
                  <p class="wallet-title">
                    YOUR WALLET
                  </p>
                  <div class="wallet-box">
                    <p>Your current MetaMask wallet address does not match any of your wallets connected to royal.</p>
                    <a href="#">Go To Wallet Settings </a>
                  </div>
                  <v-list flat>
                    <v-list-item-group
                        v-model="selectedItem"
                        color="primary"
                    >
                      <v-list-item
                          class="wallet-list"
                          v-for="(item, i) in items"
                          :key="i"
                          @click="goPage('wallets')"
                      >
                        <v-list-item-icon>
                          <img :src="require(`@/assets/images/logo/${item.icon}-logo.svg`)" alt="crypto-logo"
                               width="16" height="16"/>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.title"></v-list-item-title>
                          <p>{{ item.address }}</p>
                        </v-list-item-content>
                        <v-badge color="#DCDCDC" dot
                        ></v-badge>
                      </v-list-item>
                    </v-list-item-group>
                    <v-divider></v-divider>
                  </v-list>
                  <v-btn class="wallet-button" text>
                    + ADD A WALLET
                  </v-btn>
                </v-card>
              </v-fade-transition>
            </div>
          </v-hover>
          <v-hover v-slot="{hover}">
            <div>
              <v-avatar
                  size="30">
                <img
                    :src=profileInfo.avatarAsset.filename
                    alt="profile-avatar"
                >
              </v-avatar>
              <v-fade-transition>
                <v-card
                    v-if="hover"
                    class="avatar"
                    outlined
                    width="170px"
                >
                  <p @click="goPage('my-profile')">MY PROFILE</p>
                  <p @click="goPage('profile')">SETTINGS</p>
                  <p>CONTACT SUPPORT</p>
                  <p @click="logout">LOGOUT</p>
                </v-card>
              </v-fade-transition>
            </div>
          </v-hover>
        </div>
        <div v-else class="header-right">
          <div class="before-login">
            <a @click="goPage('auth')">LOGIN</a>
            <a @click="goPage(`register`)">SIGN UP</a>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import navMenuItems from '@/navigation'
import NavMenuHeaderLink from './NavMenuHeaderLink.vue'

export default {
  components: {
    NavMenuHeaderLink,
  },
  data() {
    return {
      isShow: true,
      selectedItem: 1,
      items: [
        {title: 'Ethereum', icon: 'eth', address: '0XB66E...4EC2'},
        {title: 'Polygon', icon: 'matic', address: '0XB66E...4EC2'},
      ],
      drawer: null,
      drawerItems: [
        {title: 'DASHBOARD', name: 'dashboard'},
        {title: 'MARKETPLACE', name: 'marketplace'},
      ],
      active: false,
      profileInfo: {},
    }
  },
  setup() {
    const resolveNavComponent = item => {
      return 'nav-menu-header-link'
    }
    return {
      navMenuItems,
      resolveNavComponent,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    }
  },
  mounted() {
    this.profile()
  },
  methods: {
    changeActive() {
      this.active = !this.active
    },
    goPage(name) {
      this.$router.push({name: name})
    },
    logout() {
      this.$router.push({name: 'home'})
      this.$store.dispatch('app/logout')
    },
    profile() {
      if (this.$store.state.app.user) {
        this.profileInfo = this.$store.state.app.user.userData.data.profile
      }
    }
  }
}
</script>
