export default [
  {
    title: 'Marketplace',
    route: 'marketplace',
    active: false,
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    active: false,
  },
]
