<template>
  <router-link :class="{ 'menuActive':$route.name === item.route}" :to="{ name: item.route }"
               :key="item.route">
    {{ item.title }}
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      aaa: false,
      myItem: null
    }
  },

  methods: {}
}
</script>
