<template>
  <footer class="footer" :style="bgColor(type)">
    <div>
      <img
          src="@/assets/images/logo/logo-white.png"
          alt="logo-white"
      />
      <p>
        Help Center
      </p>
      <p>
        Privacy
      </p>
    </div>
    <div class="copyright">
      ©{{ year }} by enterBlock Capital. Proudly created with enterBlock
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  props: {
    type: String,
    default: 'main',
  },
  data() {
    return {
    }
  },
  computed: {
    year() {
      return (new Date()).getFullYear()
    },
  },
  methods: {
    bgColor(type) {
      return {
        "background-color" : type === 'main' ? '#000000' : '#ffffff',
        "color" : type === 'main' ? '#ffffff' : '#000000'
      }
    }
  }
}
</script>
